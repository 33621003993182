import React from 'react';
import PropTypes from 'prop-types';

import Highcharts from './highcharts';

class Chart extends React.PureComponent {
  render() {
    return <div id={this.props.id} style={{ flex: 1 }} />;
  }

  componentDidMount() {
    const { options } = this.props;
    this.chart = new Highcharts.Chart(this.props.id, options);
  }

  componentWillReceiveProps(nextProps) {
    const { id, options } = this.props;
    const nextOptions = nextProps.options;
    /* eslint no-underscore-dangle: 0 */
    const data =
      options.series && options.series.length
        ? options.series.map((serie) => {
            const s = serie;
            s._symbolIndex = null;
            return s;
          })
        : [];
    const nextData =
      nextOptions.series && nextOptions.series.length
        ? nextOptions.series.map((serie) => {
            const s = serie;
            s._symbolIndex = null;
            return s;
          })
        : [];
    if (JSON.stringify(data) !== JSON.stringify(nextData)) {
      this.chart = new Highcharts.Chart(id, nextOptions);
    }
  }
}

Chart.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
};

export default Chart;

import { useEffect, useState } from 'react';

import ChartArea from 'components/base/ChartArea';
import Loader from 'components/base/Loader';
import { requestChart } from 'services/api';

import { getPeriodLabelFormat } from 'utils/appFunctions';
import useStore from '../../store';

import Colors from 'utils/colors';

import Balance from 'components/base/Balance';
import BoxWrapper from 'components/scaffolding/BoxWrapper';

const Aum = ({
  entityFilters
}) => {
  const [data, setData] = useState();
  const userToken = useStore((state) => state.token);
  const entity = useStore((state) => state.entity);

  useEffect(() => {
    requestChart('aum', userToken, entity).then((data) => {
      setData(data);
      window.parent.postMessage('loaded', '*');
    });
  }, [userToken, entity]);

  if (!data) return <Loader />;
  const chartData = [{ data: data.chartData, name: 'AUM' }];

  return (
    <BoxWrapper>
      <Balance amount={data.amount} variation={data.variation100} currency={entityFilters.currency} />
      <ChartArea
        color={Colors.ChartArea}
        labelFormat={getPeriodLabelFormat()}
        data={chartData}
        currency={entityFilters.currency}
      />
    </BoxWrapper>
  );
};
export default Aum;

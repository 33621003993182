import { useEffect, useState } from 'react';
import { getPeriodLabelFormat } from 'utils/appFunctions';
import Colors from 'utils/colors';

import ChartHistogram from 'components/base/ChartHistogram';

import { requestChart } from 'services/api';
import useStore from './../../store';

import Loader from 'components/base/Loader';
import Balance from 'components/base/Balance';
import BoxWrapper from 'components/scaffolding/BoxWrapper';

const FlowsME = ({
  entityFilters
}) => {
  const [data, setData] = useState();
  const userToken = useStore((state) => state.token);
  const entity = useStore((state) => state.entity);

  useEffect(() => {
    requestChart('flowsME', userToken, entity).then((data) => {
      setData(data);
      window.parent.postMessage('loaded', '*');
    });
  }, [userToken, entity]);

  if (!data) return <Loader />;

  const chartData = data.chartDataCollection.filter(
    (item) => item.name !== 'netflows'
  );

  return (
    <BoxWrapper>
      <Balance amount={data.variation} variation={data.variation100} currency={entityFilters.currency} />
      <ChartHistogram
        colors={Colors.ChartHistogram}
        labelFormat={getPeriodLabelFormat()}
        data={chartData}
        currency={entityFilters.currency}
      />
    </BoxWrapper>
  );
};
export default FlowsME;

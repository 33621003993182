import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import { v4 as uuidv4 } from 'uuid';

import { formatMoney } from 'utils/appFunctions';
import Chart from 'components/scaffolding/Chart';
import { symbols } from 'utils/currency'

/* eslint-disable react/prefer-stateless-function */
class ChartArea extends React.PureComponent {
  getChartOptions(data, color) {
    const { labelFormat, percentage, hLines, currency } = this.props;

    let yAxisMax;
    let yAxisMin;
    const opacities = [
      [0.25, 0.05],
      [0.2, 0.04],
      [0.15, 0.03],
      [0.1, 0.01],
      [0.0, 0.01],
      [0.0, 0.01],
    ];
    const gradients = [
      { x1: 0, y1: 0, x2: 0, y2: 0.9, x3: 0, y3: 0.9 },
      { x1: 0, y1: 0, x2: 0, y2: 0.75, x3: 0, y3: 0.75 },
      { x1: 0, y1: 0, x2: 0, y2: 0.05, x3: 0, y3: 0.05 },
      { x1: 0, y1: 0, x2: 0, y2: 0.01, x3: 0, y3: 0.01 },
      { x1: 0, y1: 0, x2: 0, y2: 0.01, x3: 0, y3: 0.01 },
      { x1: 0, y1: 0, x2: 0, y2: 0.01, x3: 0, y3: 0.01 },
    ];

    const series =
      data && data.length
        ? data.map((serie) => {
            const serieData =
              serie.data && serie.data.length
                ? serie.data.map((d, dataIndex) => {
                    let value;
                    if (percentage) {
                      value =
                        (parseInt(d.value, 10) /
                          data
                            .map((datatotal) =>
                              datatotal.data && datatotal.data[dataIndex]
                                ? datatotal.data[dataIndex].value
                                : 0
                            )
                            .reduce((p, n) => p + n)) *
                        100;
                    } else {
                      value = parseInt(d.value, 10);
                    }
                    const dateParts = d.date.split('-');
                    const date = Date.UTC(
                      dateParts[0],
                      dateParts[1] - 1,
                      dateParts[2]
                    );
                    if (!yAxisMin || value < yAxisMin) yAxisMin = value;
                    if (!yAxisMax || value > yAxisMax) yAxisMax = value;
                    return [date, value];
                  })
                : [];

            serieData.sort((a, b) => a[0] - b[0]);
            const type = 'area';
            const name = serie.name;
            const showInLegend = false;
            const borderColor = color;
            const opacityIndex =
              data.length <= opacities.length ? data.length : opacities.length;
            const fillColor = {
              linearGradient: gradients[data.length - 1],
              stops: [
                [0, color],
                [
                  0.7,
                  Highcharts.Color(color)
                    .setOpacity(opacities[opacityIndex - 1][0])
                    .get('rgba'),
                ],
                [
                  1,
                  Highcharts.Color(color)
                    .setOpacity(opacities[opacityIndex - 1][1])
                    .get('rgba'),
                ],
              ],
            };
            return {
              data: serieData,
              name,
              type,
              color,
              showInLegend,
              borderColor,
              fillColor,
            };
          })
        : null;

    let ticks =
      series && series.length && series[0].data && series[0].data.length <= 16
        ? series[0].data.map((d) => d[0])
        : null;
    if (
      ticks &&
      ticks.length > 3 &&
      new Date(ticks[ticks.length - 1]) - new Date(ticks[ticks.length - 2]) <
        (new Date(ticks[ticks.length - 2]) -
          new Date(ticks[ticks.length - 3])) /
          2
    ) {
      ticks = ticks.filter((tick, index) => index !== ticks.length - 2);
    }
    const plotLines =
      hLines && hLines.length
        ? hLines.map((line) => {
            if (line.value && (!yAxisMin || line.value < yAxisMin))
              yAxisMin = line.value;
            if (line.value && (!yAxisMax || line.value > yAxisMax))
              yAxisMax = line.value;
            return {
              value: line.value,
              color: 'white',
              dashStyle: 'shortdash',
              width: 1,
              label: {
                text: line.name,
                style: {
                  color: 'white',
                },
              },
            };
          })
        : null;
    const axisRange = yAxisMax - yAxisMin;
    yAxisMin -= axisRange * 0.05;
    yAxisMax += axisRange * 0.05;
    const chartOptions = {
      title: {
        text: '',
      },
      chart: {
        type: 'area',
        marginTop: 10,
        spacingTop: 30,
        spacingBottom: 5,
        spacingLeft: 5,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: `{value: ${
            labelFormat || window.GLOBALDASHBOARDDATA.periodLabelFormat
          }}`,
        },
        lineColor: '#E5E5E5',
        tickPositions: ticks,
      },
      yAxis: {
        // type: 'logarithmic',
        min: yAxisMin,
        max: yAxisMax,
        title: '',
        gridLineWidth: 1,
        gridLineColor: '#E5E5E5',
        labels: {
          formatter() {
            return percentage
              ? `${this.value}%`
              : this.axis.defaultLabelFormatter.call(this);
          },
        },
        plotLines,
      },
      tooltip: {
        formatter() {
          let s = '';
          const points = this.points;
          points.sort((a, b) => b.y - a.y);
          for (const point of points) {
            if (s === '')
              s += `<b style="color:#808ab1;">${Highcharts.dateFormat(
                '%e %b %y',
                new Date(point.x)
              )}</b><br>`;
            s += `<p style="color:#808ab1;">${point.series.name}</p><br>`;
            if (percentage) {
              s += `<p>${point.y.toFixed(2)}%</p><br>`;
            } else {
              s += `${`<p>${symbols[currency]}${formatMoney(point.y)}</p><br>`}`;
            }
          }
          return s;
        },
        shared: true,
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
            y: -30,
          },
        },
      },
      series,
    };
    return chartOptions;
  }

  render() {
    const { data, color } = this.props;
    const chartOptions = this.getChartOptions(data, color);
    return <Chart id={uuidv4()} options={chartOptions} />;
  }
}

ChartArea.propTypes = {
  data: PropTypes.array,
  color: PropTypes.string,
  labelFormat: PropTypes.string,
  percentage: PropTypes.bool,
  hLines: PropTypes.array,
  currency: PropTypes.string,
};

ChartArea.defaultProps = {
  data: [
    {
      name: 'text',
      data: [{ value: 0, date: '0000-00-00' }],
    },
  ],
  color: '#fff',
  labelFormat: '',
  percentage: false,
  hLines: null,
};

export default ChartArea;

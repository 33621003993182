import { formatMoney } from 'utils/appFunctions';

import styles from './index.module.css';
import { symbols } from 'utils/currency'

const Balance = ({ amount, variation, currency }) => {
  return (
    <div className={styles.container}>
      <h2>{symbols[currency]}{formatMoney(amount)}</h2>
      {variation && (
        <span>
          <b>
            {variation > 0 ? '↑' : '↓'}
            {variation.toFixed(2)}%
          </b>{' '}
          Variation since last year
        </span>
      )}
    </div>
  );
};

export default Balance;

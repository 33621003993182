const Colors = {
  ChartHistogram: {
    total: 'grey',
    flows: '#E7CA20',
    marketEffect: '#6600D5',
    netflows: '#5cd5f0',
  },
  ChartArea: '#004AD5',
};

export default Colors;

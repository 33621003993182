import Highcharts from 'highcharts/highstock';
require('highcharts/modules/exporting')(Highcharts);

Highcharts.theme = {
  global: {
    useUTC: false,
  },
  chart: {
    backgroundColor: 'transparent',
    spacing: [0, 0, 0, 0],
    // style: {
    //   fontFamily: 'IBM Plex Sans',
    // }
  },

  title: {
    style: {
      font: 'normal 16px',
    },
  },

  legend: {
    itemStyle: {
      color: '#53565a',
      font: 'normal 14px',
      padding: 4,
    },
  },

  tooltip: {
    outside: true,
    borderWidth: 0,
    borderRadius: 7,
    backgroundColor: '#1a213f',
    shadow: false,
    padding: 16,
    style: {
      padding: 12,
      color: '#fff',
      lineHeight: '18px',
    },
  },

  labels: {
    style: {},
  },

  credits: {
    style: {
      cursor: 'none',
      pointerEvents: 'none',
      color: 'transparent',
    },
  },

  textColor: '#53565a',

  xAxis: {
    lineWidth: 1,
    lineColor: '#e7e8eb',
    tickColor: 'transparent',
    labels: {
      style: {
        color: '#9b9b9b',
      },
    },
    title: {
      style: {
        font: 'normal 14px',
      },
    },
  },

  yAxis: {
    lineWidth: 0,
    lineColor: '#e7e8eb',
    gridLineColor: '#e7e8eb',
    minorGridLineWidth: 0,
    labels: {
      style: {
        color: '#9b9b9b',
      },
    },
    title: {
      style: {
        font: 'normal 14px',
      },
    },
  },

  plotOptions: {
    series: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
            radius: 3,
            lineColor: 'transparent',
          },
        },
      },
      bar: {
        borderColor: 'transparent',
      },
      groupPadding: 0.05,
      pointPadding: 0,
    },
  },
};
Highcharts.setOptions(Highcharts.theme);
Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
    thousandsSep: '.',
    numericSymbols: ['k', 'M', 'B', 'T', 'Q'],
  },
});

// function correctFloat(num, prec) {
//   return parseFloat(
//     num.toPrecision(prec || 40)
//   );
// }
// Highcharts.correctFloat = correctFloat;

// // Pass error messages
// Highcharts.Axis.prototype.allowNegativeLog = true;

// // Override conversions
// function log2lin(num) {
//   const isNegative = num < 0;
//   let adjustedNum = Math.abs(num);
//   if (adjustedNum < 10) {
//     adjustedNum += (10 - adjustedNum) / 10;
//   }
//   const result = Math.log(adjustedNum) / Math.LN10;
//   return isNegative ? -result : result;
// }
// Highcharts.Axis.prototype.log2lin = log2lin;

// function lin2log(num) {
//   const isNegative = num < 0;
//   const absNum = Math.abs(num);
//   let result = 10 ** absNum;
//   if (result < 10) {
//     result = (10 * (result - 1)) / (10 - 1);
//   }
//   return isNegative ? -result : result;
// };
// Highcharts.Axis.prototype.lin2log = lin2log;
Highcharts.Chart = Highcharts.chart;
export default Highcharts;

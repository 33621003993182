import create from 'zustand';
import produce from 'immer';

import userSlice from './slices/userSlice';

const immer = (config) => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === 'function'
    ? produce(partial)
    : partial;
  return set(nextState, replace);
}, get, api);

const useStore = create(
  immer((set) => ({
    ...userSlice(set),
  })),
);

export default useStore;

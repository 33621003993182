const { requestUserData, requestFundhouses } = require("services/api");

export const requestEntity = async (jwtToken) => {
  const userData = await requestUserData(jwtToken);
  
  let entity = -1
  if (userData.allowed !== 'all') {
    const fundhouses = await requestFundhouses(jwtToken)
    entity = fundhouses.data.at(0).id
  }

  return entity
}
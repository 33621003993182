
const userSlice = (set) => ({
  user:  undefined,
  token:  undefined,
  entity: undefined,
  setUser: (user) => set({ user }),
  setUserToken : (token) => set({ token }),
  setEntity: (entity) => set({ entity }),
});

export default userSlice;

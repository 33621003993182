import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const requestAuthToken = async (token) => {
  try {
    const response = await axios.post(`${API_URL}/auth/token`, {
      token,
    });

    const jwtToken = response.data.token;
    return jwtToken;
  } catch (error) {
    window.parent.postMessage('error', '*');
    throw error;
  }
};

const requestUserData = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${API_URL}/user`, config);
    return response.data;
  }
  catch (error) {
    window.parent.postMessage('error', '*');
    throw error;
  }
}

const requestFundhouses = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${API_URL}/filter/managementgroup`, config);
    return response.data;
  }
  catch (error) {
    window.parent.postMessage('error', '*');
    throw error;
  }
}


const requestChart = async (type, token, entity) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${API_URL}/${type}/${entity}?currency=EUR&period=12LM`,
      config
    );
    return response.data.data;
  } catch (error) {
    window.parent.postMessage('error', '*');
    throw error;
  }
};

export { requestAuthToken, requestFundhouses, requestUserData, requestChart };

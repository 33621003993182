const getPeriodLabelFormat = (
  period = 'YTD',
  multiline = false,
  short = false
) => {
  switch (period) {
    case 'WTD':
      return multiline ? '%e <br/>%b' : '%e %b';
    case 'MTD':
      return multiline ? '%e <br/>%b' : '%e %b';
    case 'YTD':
      if (short) return '%b %y';
      return multiline ? '%e <br/>%b %y' : '%e %b %y';
    case 'SB':
      if (short) return '%b %y';
      return multiline ? '%e <br/>%b %y' : '%e %b %y';
    default:
      break;
  }
  if (/[0-9]LD/.test(period)) {
    return multiline ? '%e <br/>%b' : '%e %b';
  } else if (/[0-9]LQ/.test(period)) {
    if (short) return '%b <br/>%y';
    return multiline ? '%e <br/>%b %y' : '%e %b %y';
  }
  return multiline ? '%e <br/>%b' : '%e %b';
};

const formatMoney = (
  amount,
  decimalCount = 0,
  decimal = '.',
  thousands = ',',
  minify = false
) => {
  let suffix = '';
  let money;
  const minifyAmount = minify;
  if (minifyAmount) {
    money = parseFloat(amount);
    if (Math.abs(amount) >= 1000 && Math.abs(amount) < 1000000) {
      money = parseFloat(money / 1000);
      suffix = 'k';
    }
    if (Math.abs(amount) >= 1000000 && Math.abs(amount) < 1000000000) {
      money = parseFloat(money / 1000000);
      suffix = 'M';
    }
    if (Math.abs(amount) >= 1000000000 && Math.abs(amount) < 1000000000000) {
      money = parseFloat(money / 1000000000);
      suffix = 'Bn';
    }
    if (Math.abs(amount) >= 1000000000000) {
      money = parseFloat(money / 1000000000000);
      suffix = 'Tn';
    }
    money = parseInt(money * 100, 10) / 100;
    return `${money} ${suffix}`;
  } else if (!decimalCount) {
    money = amount ? parseFloat(amount) : 0;
  }
  try {
    const decimals = Number.isNaN(decimalCount) ? 2 : Math.abs(decimalCount);
    const negativeSign = money < 0 ? '-' : '';
    let cleanAmount;
    const i = parseInt(
      (cleanAmount = Math.abs(Number(money) || 0).toFixed(decimals)),
      10
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    const v =
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimals
        ? decimal +
          Math.abs(cleanAmount - i)
            .toFixed(decimals)
            .slice(2)
        : '');
    return v + (suffix ? ` ${suffix}` : '');
  } catch (e) {
    return '';
  }
};

export { getPeriodLabelFormat, formatMoney };

/**
 *
 * ChartHistogram
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import { v4 as uuidv4 } from 'uuid';

import { formatMoney } from 'utils/appFunctions';
import Chart from 'components/scaffolding/Chart';
import { symbols } from 'utils/currency'

/* eslint-disable react/prefer-stateless-function */
class ChartHistogram extends React.PureComponent {
  getChartOptions(data, colors) {
    const { labelFormat, currency } = this.props;
    const series =
      data && data.length
        ? data.map((serie) => {
            const seriedata =
              serie.data && serie.data.length
                ? serie.data.map((d) => {
                    const dateParts = d.date.split('-');
                    const date = Date.UTC(
                      dateParts[0],
                      dateParts[1] - 1,
                      dateParts[2]
                    );
                    const value = d.diff ? parseInt(d.diff, 10) : 0;
                    return [date, value];
                  })
                : [];
            seriedata.sort((a, b) => a[0] - b[0]);
            const name = serie.name;
            const type = name === 'total' ? 'line' : 'column';
            const dashStyle = type === 'line' ? 'dash' : 'solid';
            const color = colors[serie.name];
            const showInLegend = false;
            return {
              name,
              data: seriedata,
              type,
              color,
              showInLegend,
              dashStyle,
            };
          })
        : null;

    let ticks =
      series && series.length && series[0].data && series[0].data.length <= 16
        ? series[0].data.map((d) => d[0])
        : null;
    if (
      ticks &&
      ticks.length > 3 &&
      new Date(ticks[ticks.length - 1]) - new Date(ticks[ticks.length - 2]) <
        (new Date(ticks[ticks.length - 2]) -
          new Date(ticks[ticks.length - 3])) /
          2
    ) {
      ticks = ticks.filter((tick, index) => index !== ticks.length - 2);
    }
    const chartOptions = {
      title: {
        text: '',
      },
      chart: {
        type: 'column',
        marginTop: 10,
        spacingTop: 30,
        spacingBottom: 5,
      },
      xAxis: {
        type: 'datetime',
        lineWidth: 0,
        labels: {
          format: `{value: ${labelFormat}}`,
        },
        tickPositions: ticks,
      },
      yAxis: {
        title: '',
        gridLineWidth: 0,
        plotLines: [
          {
            value: 0,
            color: '#000',
            dashStyle: 'line',
            width: 1,
          },
        ],
      },
      plotOptions: {
        series: {
          groupPadding: 0.35,
          pointPadding: 0.01,
          pointWidth: 10,
          borderWidth: 0,
          // At some point tooltips are not hiding properly, this tries to avoid the tooltips to persist
          events: {
            mouseOut() {
              this.chart.update({
                tooltip: {
                  enabled: false,
                },
              });
            },
            mouseOver() {
              this.chart.update({
                tooltip: {
                  enabled: true,
                },
              });
            },
          },
        },
      },
      tooltip: {
        formatter() {
          let s = '';
          if (this.points) {
            for (const point of this.points) {
              const name = point.series.name;
              s += `<br><b style="color:#808ab1;text-transform: capitalize;">${name} ${Highcharts.dateFormat(
                '%e %b %y',
                new Date(point.x)
              )}</b><br>`;
              s += `<p>${point.y < 0 ? '-' : ''}${symbols[currency]}${formatMoney(
                Math.abs(point.y)
              )}</p>`;
            }
          }
          return s;
        },
        shared: true,
      },
      exporting: {
        buttons: {
          contextButton: {
            // enabled: uniqueClass ? true : false,
            enabled: false,
            y: -30,
          },
        },
      },
      series,
    };
    return chartOptions;
  }

  render() {
    const { data, colors } = this.props;
    const chartOptions = this.getChartOptions(data, colors);

    return chartOptions ? <Chart id={uuidv4()} options={chartOptions} /> : null;
  }
}

ChartHistogram.propTypes = {
  data: PropTypes.array,
  labelFormat: PropTypes.string,
  colors: PropTypes.object,
  currency: PropTypes.string,
};

ChartHistogram.defaultProps = {
  colors: ['#fff'],
  labelFormat: '',
  data: [
    {
      name: 'current',
      type: 'column',
      data: [
        { value: 13016678509.61706, date: '2018-01-01' },
        { value: 4016678509.61706, date: '2018-02-01' },
        { value: 22016678509.61706, date: '2018-03-01' },
        { value: 10016678509.61706, date: '2018-04-01' },
        { value: 16016678509.61706, date: '2018-05-01' },
      ],
    },
  ],
};

export default ChartHistogram;

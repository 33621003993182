import { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import Aum from 'widgets/Aum';
import FlowsME from 'widgets/FlowsME';
import useStore from 'store';
import Loader from 'components/base/Loader';

import { 
  requestAuthToken,
} from 'services/api';
import { requestEntity } from 'utils/auth';

const App = () => {
  const [loading, setLoading] = useState(true);
  const setUserToken = useStore((state) => state.setUserToken);
  const setEntity = useStore((state) => state.setEntity);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    requestAuthToken(token).then((jwtToken) => {
      requestEntity(jwtToken)
        .then((entity) => {
          setUserToken(jwtToken);
          setEntity(entity);
          setLoading(false);
        })
    });
  }, [setEntity, setUserToken]);

  if (loading) return <Loader />;
  
  return (
    <Router basename={'/widgets'}>
      <Switch>
        <Route path="/aum">
          <Aum entityFilters={{currency: 'EUR'}}/>
        </Route>
        <Route path="/flows">
          <FlowsME entityFilters={{currency: 'EUR'}}/>
        </Route>
        <Redirect from="/" to="/aum" />
      </Switch>
    </Router>
  );
};

export default App;
